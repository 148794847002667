import React, { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { HttpData, HttpNoData } from '../../../Core';
import styles from '../Style/Favorites.module.css';

function Favorites() {

    const [currentCategory, setCurrentCategory] = useState({});
    const [currentWorks, setCurrentWorks] = useState([]);
    const [categories, setCategories] = useState([]);
    const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));
    const [editWorks, setEditWorks] = useState([]);

    useEffect(() => {
        async function DidMount() {

            var responseCategories = await HttpNoData('/api/ExtraWorkCategories', 'GET', jwt);

            if (!responseCategories.statusSuccessful)
                console.log(responseCategories.error);

            setCategories(responseCategories.data);
            setCurrentCategory(responseCategories.data[0]);

            var responseWorks = await HttpNoData('/api/ExtraWorks/favorite/category/' + responseCategories.data[0].id, 'GET', jwt);

            if (!responseWorks.statusSuccessful)
                console.log(responseWorks.error);

            setCurrentWorks(responseWorks.data);
        }

        DidMount();
    }, []);

    const UpdateCurrentCategory = async (category) => {

        setCurrentCategory(category);

        var responseWorks = await HttpNoData('/api/ExtraWorks/favorite/category/' + category.id, 'GET', jwt);

        if (!responseWorks.statusSuccessful)
            console.log(responseWorks.error);

        setEditWorks([]);
        setCurrentWorks(responseWorks.data);
    }

    const UpdateWork = (work) => {

        const updateList = currentWorks;
        const index = updateList.findIndex(item => +item.id === +work.id);

        const updatedItem = updateList[index];
        updatedItem.isEnable = !updatedItem.isEnable;

        var indexEdit = editWorks.findIndex(o => +o.id == +work.id);

        if (indexEdit == -1)
            setEditWorks([...editWorks, updatedItem]);
        else setEditWorks([...editWorks.filter(o => +o.id != +updatedItem.id)]);

        updateList[index] = updatedItem;

        setCurrentWorks([...updateList]);
    }

    const ClickSave = async () => {

        const listAppend = editWorks.filter(o => o.isEnable).map(o => o.id);
        const listDelete = editWorks.filter(o => !o.isEnable).map(o => o.id);

        if (listAppend.length > 0) {

            const responseSave = await HttpData('/api/ExtraWorks/favorites', 'POST', jwt, listAppend);

            if (!responseSave.statusSuccessful)
                console.log(responseSave.error);

        }

        if (listDelete.length > 0) {
            const responseDelete = await HttpData('/api/ExtraWorks/favorites', 'DELETE', jwt, listDelete);

            if (!responseDelete.statusSuccessful)
                console.log(responseDelete.error);
        }

        setEditWorks([]);
    }

    const categoryUI = categories.map((item) =>
        <button key={item.id} className={+item.id === +currentCategory.id ? styles.currentPlaceButton : styles.placeButton} onClick={() => { UpdateCurrentCategory(item); }}>
            {item.name}
        </button>);

    const worksUI = currentWorks.map((item) =>
        <div key={item.id} className={styles.item}>
            <div className={styles.item_text}>{item.name}</div>
            <button className={item.isEnable ? styles.item_input_on : styles.item_input_off} onClick={() => { UpdateWork(item); }} >
                <BsPlus className={item.isEnable ? styles.rotateOn : styles.rotateOff} />
            </button>
        </div>
    );

    return (
        <React.Fragment>
            <div className={styles.container}>
                <div className={styles.panelButtons}>
                    {categoryUI}
                </div>
                {worksUI}
                <button disabled={editWorks.length <= 0} className={styles.save_button} onClick={() => { ClickSave(); }}>сохранить {currentCategory.name}</button>
            </div>
        </React.Fragment>
    );
}

export default Favorites;