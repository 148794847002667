import { HttpNoData } from "../Core";

export async function getExtraWorks(jwt){
    
    const response = await HttpNoData('/api/ExtraWorks', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function putCoeffExtraWork(id, value,jwt){
    
    const response = await HttpNoData(`/api/ExtraWorks/${id}/coefficient/${value}`, 'PUT', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function putUserCheckExtraWork(id, value, jwt){
    
    const response = await HttpNoData(`/api/ExtraWorks/${id}/userCheck/${value}`, 'PUT', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function putComplexityExtraWork(id, value,jwt){
    
    const response = await HttpNoData(`/api/ExtraWorks/${id}/complexity/${value}`, 'PUT', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function putNameExtraWork(id, value, jwt){
    
    const response = await HttpNoData(`/api/ExtraWorks/${id}/name/${value}`, 'PUT', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

