import React, { useEffect, useState } from "react";
import { Accordion, Badge, Button, Modal, Container, Row, Col, Form, FloatingLabel } from 'react-bootstrap'
import { getExtraWorks, putCoeffExtraWork, putComplexityExtraWork, putUserCheckExtraWork, putNameExtraWork } from "../../apiMethod/extraWorks";
import styles from './ExtraWorksView.module.css';
import { getExtraWorkCategories, putExtraWorkInCategory } from "../../apiMethod/extraWorkCategories";

function ExtraWorksView({ jwt }) {

    const [extraWorks, setExtraWorks] = useState([]);
    const [extraWorkCategories, setExtraWorkCategories] = useState([]);
    const [extaWorkLevel, setExtraWorkLevel] = useState([{ id: 0, name: "По умолчанию (Простая)"}, { id: 1, name: "Простая"}, { id: 2, name: "Средняя"}, { id: 3, name: "Сложная"}]);

    useEffect(() => {

        async function DidMount() {

            const response = await getExtraWorks(jwt);
            const responseCate = await getExtraWorkCategories(jwt);

            setExtraWorks(response);
            setExtraWorkCategories(responseCate);
        }

        DidMount();
    }, []);

    const  EditCategoryView = ({ show, handleClose, handleSave, oldValue }) => {

        const [value, setValue] = useState(oldValue);

        return <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Новое значение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Категория</Form.Label>
                        <FloatingLabel label="Выберите категорию">
                            <Form.Select aria-label="Выберите категорию" value={value} onChange={(e) => setValue(e.target.value)}>
                                {
                                    extraWorkCategories.map(o => <option key={o.id} value={o.id}>
                                        {o.name}
                                    </option>)
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={() => handleSave(value)}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const EditComplexityView = ({ show, handleClose, handleSave, oldValue }) => {

        const [value, setValue] = useState(oldValue);

        return <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Новое значение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Сложность</Form.Label>
                        <FloatingLabel label="Выберите сложность">
                            <Form.Select aria-label="Выберите сложность" value={value} onChange={(e) => setValue(e.target.value)}>
                                {
                                    extaWorkLevel.map((o, i) => <option key={i} value={o.id}>
                                        {o.name}
                                    </option>)
                                }
                            </Form.Select>
                        </FloatingLabel>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={() => handleSave(value)}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const EditNameView = ({ show, handleClose, handleSave, oldValue }) => {

        const [value, setValue] = useState(oldValue);

        return <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Новое название</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Название</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={oldValue}
                            autoFocus
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={() => handleSave(value)}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    }


    const EditCoeffView = ({ show, handleClose, handleSave, oldValue }) => {

        const [value, setValue] = useState(oldValue);

        return <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Новое значение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Коэффициент</Form.Label>
                        <Form.Control
                            type="number"
                            placeholder={oldValue}
                            autoFocus
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={() => handleSave(value)}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>
    }

    const EditStateView = ({ showEnable, handleClose, handleSave, newState }) => {

        return <Modal show={showEnable} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Подтвердите действие</Modal.Title>
            </Modal.Header>
            <Modal.Body>Вы уверены что хотите <strong>{newState}</strong> работу?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Закрыть
                </Button>
                <Button variant="primary" onClick={handleSave}>
                    Сохранить
                </Button>
            </Modal.Footer>
        </Modal>;
    }

    const ExtraWorkView = ({ extraWork }) => {
        
        const [id] = useState(extraWork.id);
        const [extraWorkCategoryId, setExtraWorkCategoryId] = useState(extraWork.extraWorkCategoryId);
        const [name, setName] = useState(extraWork.name);
        const [coefficient, setCoefficient] = useState(extraWork.coefficient);
        const [isUserCheck, setIsUserCheck] = useState(extraWork.isUserCheck);
        const [complexity, setComplexity] = useState(extraWork.complexity);

        const [showEnable, setShowEnable] = useState(false);
        const [showCoeff, setShowCoeff] = useState(false);
        const [showCategory, setShowCategory] = useState(false);
        const [showComplexity, setShowComplexity] = useState(false);
        const [showName, setShowName] = useState(false);

        const findCategory = extraWorkCategories.find(o => o.id == extraWorkCategoryId);

        return <Accordion.Item eventKey={id} key={id}>
            <Accordion.Header>
                <Container fluid="md">
                    <Row>
                        <Col>{name}</Col>
                        <Col
                        ><Badge pill style={{ marginLeft: 4, marginRight: 4 }} bg="primary">
                                {coefficient}
                            </Badge>
                            {
                                !isUserCheck ?
                                    <Badge pill bg="warning" text="dark">
                                        {"Не доступно"}
                                    </Badge> :
                                    <Badge pill bg="success">Доступно</Badge>
                            }
                            <Badge pill style={{ marginLeft: 4, marginRight: 4 }} bg={findCategory ? "info" : "warning"} text="dark">{
                                findCategory ?
                                    findCategory.name : "Без категории"
                            }</Badge>
                            <Badge pill bg="info" text="dark">{
                                extaWorkLevel.filter(o => o.id == complexity)[0].name
                            }</Badge>
                        </Col>
                    </Row>
                </Container>
            </Accordion.Header>
            <Accordion.Body>
                <Container>
                    <Row>
                        <Col style={{ marginBottom: 6 }}>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowCoeff(true)}>Коэффициент</Button>
                            </div>
                        </Col>
                        <Col style={{ marginBottom: 6 }}>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowCategory(true)}>Категория</Button>
                            </div>
                        </Col>
                        <Col >
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowComplexity(true)}>Сложность</Button>
                            </div>
                        </Col>
                        <Col >
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowName(true)}>Название</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 6 }}>
                        <Col>
                            <div className="d-grid gap-2">
                                <Button variant="outline-warning" onClick={() => setShowEnable(true)}>Отключить</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <EditNameView show={showName} handleClose={() => setShowName(false)} handleSave={(v) => {
                    setShowName(false);
                    putNameExtraWork(id, v, jwt);
                    setName(v);
                }} oldValue={name} />
                <EditComplexityView show={showComplexity} handleClose={() => setShowComplexity(false)} handleSave={(v) => {
                    setShowComplexity(false);
                    putComplexityExtraWork(id, v, jwt);
                    setComplexity(v);
                }} oldValue={complexity} />
                <EditCategoryView show={showCategory} handleClose={() => setShowCategory(false)} handleSave={(v) => {
                    setShowCategory(false);
                    putExtraWorkInCategory(v, id, jwt);
                    setExtraWorkCategoryId(v);
                }} oldValue={extraWorkCategoryId} />
                <EditCoeffView show={showCoeff} handleClose={() => setShowCoeff(false)} handleSave={(v) => {
                    setShowCoeff(false);
                    putCoeffExtraWork(id, v, jwt);
                    setCoefficient(v);
                }} oldValue={coefficient} />
                <EditStateView showEnable={showEnable} handleClose={() => setShowEnable(false)} handleSave={() => {
                    setShowEnable(false);
                    putUserCheckExtraWork(id, !isUserCheck, jwt);
                    setIsUserCheck(!isUserCheck);
                }} newState={isUserCheck ? "Выключить" : "Включить"} />
            </Accordion.Body>
        </Accordion.Item>;
    }

    return <div className={styles.psevdoBody}>
        <Accordion>
            {
                extraWorks && extraWorks.length > 0 ?
                    extraWorks.map(o => <ExtraWorkView key={o.id} extraWork={o} />) : "Куда-то делись все работы..."
            }
        </Accordion>
    </div>;
}

export default ExtraWorksView;