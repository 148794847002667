import React from "react";
import { HttpNoData } from "../Core";

export async function getExtraWorkCategories(jwt){

    const response = await HttpNoData('/api/extraWorkCategories', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function putExtraWorkInCategory(id, extraWorkId, jwt){

    const response = await HttpNoData(`/api/extraWorkCategories/${id}/${extraWorkId}`, 'PUT', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}