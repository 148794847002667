import { HttpNoData } from "../Core";

export async function extraWorksNoUse(shiftId, jwt) {
    const response = await HttpNoData('/api/extraworks/nouse/' + shiftId, 'GET', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function extraWorkPullUpdateValue(shiftId, extraWorkPullId, value, jwt) {

    const response = await HttpNoData('/api/ExtraWorkPulls/updateValue/' + shiftId + "/" + extraWorkPullId + "/" + value, "PUT", jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function extraWorkPullUpdateDescription(shiftId, extraWorkPullId, description, jwt) {

    const response = await HttpNoData('/api/ExtraWorkPulls/updateDescription/' + shiftId + "/" + extraWorkPullId + "?newDescription=" + description, "PUT", jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function extraWorkPullDelete(shiftId, extraWorkPullId, jwt) {

    var response = await HttpNoData('/api/ExtraWorkPulls/' + shiftId + "/" + extraWorkPullId, "DELETE", jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function extraWorkPullCreate(shiftId, extraWorkId, value, description, jwt) {

    const response = await HttpNoData('/api/ExtraWorkPulls/' + shiftId + "/" + extraWorkId + "/" + value + "?description=" + description, "POST", jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getShiftsAll (date,jwt) {

    const response = await HttpNoData('/api/shifts/all/history?date=' + date, 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function shiftUpdateDate (shiftId, start, end,jwt) {
    var response = await HttpNoData('/api/shifts/editTime/' + shiftId + '/' + start + '/' + end, 'PUT', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function shiftUpdateDayOff (shiftId, state,jwt) {
    var response = await HttpNoData('/api/shifts/isDayOff/' + shiftId + '/' + state, 'PUT', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function lasers (jwt) {
    var response = await HttpNoData('/api/lasers', 'GET', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function laserOnShiftDelete (shiftId, laserId,jwt) {
    var response = await HttpNoData('/api/shifts/'+shiftId+'/laser/' + laserId, 'DELETE', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}

export async function laserOnShiftCreate (shiftId,laserId, jwt) {
    var response = await HttpNoData('/api/shifts/'+shiftId+'/laser/' + laserId, 'POST', jwt);

    if (!response.statusSuccessful)
        console.log(response.error);

    return response.statusSuccessful;
}