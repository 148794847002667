import React from "react";
import { HttpNoData } from "../../Core";

export async function getMeAnalyze(jwt){

    const response = await HttpNoData('/api/shifts/mouth/current/analyze', 'GET', jwt);

      if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}