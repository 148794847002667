import { useEffect, useState } from "react";
import { HttpNoData } from "../../Core";
import styles from './Workers.module.css';

function Workers(props) {

    const Employees = () => {

        const [categoryWorkers, setCategoryWorkers] = useState([]);
        const [workers, setWorkers] = useState([]);

        useEffect(() => {
            async function DidMount(){
                const responseCategories = await HttpNoData('/api/workerCategories', 'GET', jwt);

                if(!responseCategories.statusSuccessful)
                {
                    console.log(responseCategories.error);
                    alert('Похоже сайт отказался с вами работать');
                    return;
                }

                setCategoryWorkers(responseCategories.data);

                const responseWorkers = await HttpNoData('/api/workers', 'GET', jwt);

                if(responseWorkers.statusSuccessful)
                    setWorkers(responseWorkers.data);
                else console.log(responseWorkers.error);
            }

            DidMount();
        }, []);

        const options = categoryWorkers.map(item => <option key={item.id} value={item.id}>{item.name}</option>);

        const updateCategoryWorker = async(worker, newId) => {

            const response = await HttpNoData('/api/workerCategories?workerId=' + worker.id + '&categoryId='+ newId, 'PUT', jwt);

            if(!response.statusSuccessful)
            {
                console.log(response.error);
                alert('Похоже сайт отказался с вами работать');
                return;
            }

            const copyWorkers = [...workers];
            const index = copyWorkers.findIndex(item => item.id == worker.id);

            const copyWorker = { ...copyWorkers[index] }
            copyWorker.workerCategoryId  = newId;
            copyWorkers[index] = copyWorker;
            setWorkers(copyWorkers);
        }

        const workersView = workers.map(item => <div key={item.id} className={item.workerCategoryId == null ? styles.workerNoCategory : styles.worker}>
            <label>
                {item.name}
            </label>
            <select value={item.workerCategoryId} className={styles.categoryWorker} onChange={(e) => updateCategoryWorker(item, e.target.value)}>
                { options }
            </select>
        </div>);

        return <div className={styles.employees}>
            {
                workersView.length > 0 ? workersView : 'Уволили что ли всех?'
            }
        </div>
    }

    const Managers = () => {
        const [managers, setManagers] = useState([]);

        useEffect(() => {
            async function DidMount(){

                const responseManagers = await HttpNoData('/api/managers', 'GET', jwt);

                if(responseManagers.statusSuccessful)
                    setManagers(responseManagers.data);
                else console.log(responseManagers.error);
            }

            DidMount();
        }, []);

        const managersView = managers.map(item => <div className={styles.templateItem} key={item.id}>
            {item.name}
        </div>);

        return <div className={styles.managers}>
            {
                managersView.length > 0 ? managersView : 'Уволили что ли всех?'
            }
        </div>
    }

    const Analytics = () => {
        const [analytics, setAnalytics] = useState([]);

        useEffect(() => {
            async function DidMount(){

                const responseAnalytic = await HttpNoData('/api/analytics', 'GET', jwt);

                if(responseAnalytic.statusSuccessful)
                    setAnalytics(responseAnalytic.data);
                else console.log(responseAnalytic.error);
            }

            DidMount();
        }, []);

        const analyticsView = analytics.map(item => <div className={styles.templateItem} key={item.id}>
            {item.name}
        </div>);

        return <div className={styles.analytics}>
            {
                analyticsView.length > 0 ? analyticsView : 'Уволили что ли всех?'
            }
        </div>
    }

    const Administrators = () => {
        const [administrators, setAdministrators] = useState([]);

        useEffect(() => {
            async function DidMount(){

                const responseAdministrator = await HttpNoData('/api/Administrators', 'GET', jwt);

                if(responseAdministrator.statusSuccessful)
                    setAdministrators(responseAdministrator.data);
                else console.log(responseAdministrator.error);
            }

            DidMount();
        }, []);

        const administratorsView = administrators.map(item => <div className={styles.templateItem} key={item.id}>
            {item.name}
        </div>);

        return <div className={styles.administrators}>
            {
                administratorsView.length > 0 ? administratorsView : 'Уволили что ли всех?'
            }
        </div>
    }

    const __PANELS__ = [
        {
            name: 'Сотрудники цеха',
            callback: Employees
        },
        {
            name: 'Менеджеры',
            callback: Managers
        },
        {
            name: 'Аналитики',
            callback: Analytics
        },
        {
            name: 'Администраторы',
            callback: Administrators
        }
    ];
    const [jwt, setJwt] = useState(props.jwt);
    const [panels] = useState(__PANELS__);
    const [currentPanel, setCurrentPanel] = useState(__PANELS__[0]);

    return <div className={styles.body}>
        <div className={styles.content}>
            <div className={styles.panels}>
                {
                    panels.map((item, index) => <button key={index} onClick={() => setCurrentPanel(item)}
                        className={currentPanel.name == item.name ? styles.panelActive : styles.panel}>
                        {item.name}
                    </button>)
                }
            </div>
            <currentPanel.callback />
        </div>
    </div>;
}

export default Workers;