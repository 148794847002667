import { HttpNoData } from "../Core";

export async function getProducts(jwt){
    
    const response = await HttpNoData('/api/Products', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}

export async function getCategories(jwt){

    const response = await HttpNoData('/api/productssend/categories', 'GET', jwt);

    if(!response.statusSuccessful)
        console.log(response.error);

    return response.data;
}