import React, { useEffect, useState } from 'react';
import { CiCirclePlus } from 'react-icons/ci';
import { IoArrowBackCircle } from "react-icons/io5";
import { MdCancel, MdCheckCircle, MdEdit } from 'react-icons/md';
import { HttpNoData } from '../../../Core';
import styles from '../Style/shiftsHistory.module.css';

function ShiftsHistory() {

  const [isCreate, setIsCreate] = useState(false);
  const [isEdition, setIsEdition] = useState(false);
  const [currentEditShiftId, setCurrentEditShiftId] = useState(-1);
  const [currentEditShift, setCurrentEditShift] = useState();
  const [currentEditWork, setCurrentEditWork] = useState();
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString().split('.').reverse().join('-'));
  const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));
  const [shifts, setShifts] = useState([]);
  const [extraWorksWorld, setExtraWorksWorld] = useState([]);
  const [valueCreateWork, setValueCreateWork] = useState(0);


  const UpdateShift = (shift) => {

    var filtred = shifts.filter(item => +item.id != +shift.id);
    var sorted = [...filtred, shift].sort((a, b) => new Date(b.start) - new Date(a.start));

    setShifts([...sorted]);
  }

  const editValue = async () => {

    const value = document.getElementById('editValue').value
    var response = await HttpNoData('/api/ExtraWorkPulls/updateValue/' + currentEditShiftId + "/" + currentEditWork.id + "/" + value, "PUT", jwt);

    if (response.statusSuccessful) {

      const shift = currentEditShift;
      const works = currentEditShift.extraWorksPull;
      const workIndex = works.findIndex(item => +item.id == currentEditWork.id);
      const updateItem = works[workIndex];
      updateItem.value = value;
      works[workIndex] = updateItem;
      shift.extraWorksPull = works;
      UpdateShift(shift);
      setIsEdition(false);
      document.body.style.overflow = "scroll";
    }
    else {
      console.log(response.error);
    }
  }

  const removeJob = async () => {

    var response = await HttpNoData('/api/ExtraWorkPulls/' + currentEditShiftId + "/" + currentEditWork.id, "DELETE", jwt);

    if (response.statusSuccessful) {

      const shift = currentEditShift;
      shift.extraWorksPull = [...shift.extraWorksPull.filter(item => +item.id != +currentEditWork.id)];
      UpdateShift(shift);
      setIsEdition(false);
      document.body.style.overflow = "scroll";
    } else {
      console.log(response.error);
    }
  }

  const addJob = async () => {

    var jobSelect = document.getElementById("jobSelect");
    var newJobTitle = jobSelect.options[jobSelect.selectedIndex].textContent;
    let workId = jobSelect.value;
    var newJobValue = valueCreateWork;

    if (newJobTitle && !isNaN(newJobValue) && newJobValue > 0) {

      var response = await HttpNoData('/api/ExtraWorkPulls/' + currentEditShiftId + "/" + workId + "/" + newJobValue + "/?description=", "POST", jwt);

      if (response.statusSuccessful) {

        const shift = currentEditShift;
        shift.extraWorksPull = [...shift.extraWorksPull, response.data];
        UpdateShift(shift);
        setIsCreate(false);
        document.body.style.overflow = "scroll";
      }
      else {
        console.log(response.error);
      }
    }
  }

  const GetMyShifts = async (date) => {

    var response = await HttpNoData('/api/shifts/history?date=' + date, 'GET', jwt);

    if (response.statusSuccessful) {
      setShifts(response.data.reverse());
    }
    else {
      console.error(response.error);
    }
  }

  const TimeOnlyParse = (time) => {

    var date = time.split(':');

    if (date.length == 3) {
      var hours = date[0];
      var minutes = date[1];
      var seconds = date[2];

      return hours + "ч. " + minutes + "мин. " + seconds + "сек.";
    }
    else if (date.length == 2) {
      var minutes = date[0];
      var seconds = date[1];

      return minutes + "мин. " + seconds + "сек.";
    }

    return '';
  }

  const TimeSpanParse = (value) => {

    var points = value.split('.');

    // not days
    if (points.length == 3)
      return points[0] + "д. " + TimeOnlyParse(points[1]);
    if (points.length == 2 || value.length >= 8)
      return TimeOnlyParse(points[0]);

    return '';
  }

  const ShowCreate = async () => {

    document.body.style.overflow = "hidden";
    var responseNoUseWorks = await HttpNoData('/api/extraworks/nouse/' + currentEditShiftId, 'GET', jwt);

    if (responseNoUseWorks.statusSuccessful) {
      setExtraWorksWorld(responseNoUseWorks.data);
      setIsCreate(true);
    }
  }

  const ExtraWork = (works, isEdit) => {

    if (!(works && works.length > 0))
      return (<div>{isEdit ? <CiCirclePlus className={styles.plusExtraWork} onClick={() => { ShowCreate(); }} /> : ''}</div>);

    var worksUI = works.map(work =>
      <div key={work.id} onClick={() => { if (isEdit) { setIsEdition(true); document.body.style.overflow = "hidden"; setCurrentEditWork(work); document.getElementById('editValue').value = work.value; } }} className={isEdit ? styles.extra_work_edit : styles.extra_work}>
        <span>
          {work.name}: {work.value}
        </span>
      </div>);

    return (<div className={styles.extra_works}>
      {worksUI} {isEdit ? <CiCirclePlus className={styles.plusExtraWork} onClick={() => { ShowCreate(); }} /> : ''}
    </div>);
  }


  useEffect(() => { GetMyShifts(currentDate); }, []);

  const selectWorks = extraWorksWorld.map(item => <option key={item.id} value={item.id}>{item.name}</option>);

  const shiftsUI = shifts.map(shift =>
    <div key={shift.id} className={currentEditShiftId == shift.id ? styles.shift_edit : styles.shift}>
      <div className={styles.shiftHead}>
        <label className={styles.titleDay}>{new Date(shift.start).toLocaleString(undefined, { day: 'numeric', month: 'long' })}
          {new Date(shift.start).getDay() == new Date(shift.end).getDay() ? '' : ' - ' + new Date(shift.end).toLocaleString(undefined, { day: 'numeric', month: 'long' })}</label>
        {shift.isWorkerEdit ? <MdEdit className={styles.shiftEdit} onClick={() => { setCurrentEditShiftId(shift.id == currentEditShiftId ? -1 : shift.id); document.getElementById('editValue').value = 0; setCurrentEditShift(shift); }} /> : null}
      </div>
      <div className={styles.shift_content}>
        <div className={styles.textInfoShift}>Время: {new Date(shift.start).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })} - {new Date(shift.end).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric' })}</div>
        <div className={styles.textInfoShift}>Длительность: {TimeSpanParse(shift.timeSpan)}</div>
        <div className={styles.textInfoShift}>Сумма баллов: {shift.sumPoints}</div>
        <div className={styles.textInfoShift}>Простые баллы: {shift.simplePoints}</div>
        <div className={styles.textInfoShift}>Средние баллы: {shift.middlePoints}</div>
        <div className={styles.textInfoShift}>Сложные баллы: {shift.highPoints}</div>
        <div className={styles.textInfoShift}>Повышенные баллы: {shift.upPoints}</div>
        
        <div className={styles.dayOffContainer}>
                    <input className={styles.dayOffCheckBox} type="checkbox" onChange={() => { }} checked={shift.isDayOff}/>
                    <div className={styles.textInfoShift}>Выходной</div>
        </div>
        {ExtraWork(shift.extraWorksPull, shift.id == currentEditShiftId)}
      </div>
    </div>);

  return (
    <div className={styles.historyBody}>
      <div className={styles.panelMove}>
        <label className={styles.dateString}>{currentDate}</label>
        <input className={styles.dateInput} value={currentDate} onChange={(e) => { setCurrentDate(e.target.value); GetMyShifts(e.target.value); }} type='date' />
      </div>
      <div id="listContainer" className={styles.shift_container}>
        {shiftsUI}
      </div>
      <div className={isEdition ? styles.panelEdit : styles.objectOFF}>
        <div className={styles.panelEdit_content}>
          <label className={styles.nameItemEdit}>{currentEditWork == null ? '' : currentEditWork.name}</label>
          <input className={styles.inputItemEdit} defaultValue={0} id='editValue' type='number' />
          <div className={styles.buttonsEdit}>
            <button className={styles.buttonEditBack} onClick={() => { setIsEdition(false); document.body.style.overflow = "scroll"; }}><IoArrowBackCircle className={styles.iconEdit} /></button>
            <button className={styles.buttonEditCancel} onClick={() => { removeJob(); }}><MdCancel className={styles.iconEdit} /></button>
            <button className={styles.buttonEditSave} onClick={() => { editValue(); }}><MdCheckCircle className={styles.iconEdit} /></button>
          </div>
        </div>
      </div>
      <div className={isCreate ? styles.panelCreate : styles.objectOFF}>
        <div className={styles.panelCreate_content}>
          <select className={styles.selectWorks} id='jobSelect'>
            {selectWorks}
          </select>
          <input type='number' className={styles.inputItemEdit} value={valueCreateWork} onChange={(e) => { setValueCreateWork(e.target.value); }} />
          <div className={styles.buttonsEdit}>
            <button className={styles.buttonEditBack} onClick={() => { setIsCreate(false); document.body.style.overflow = "scroll"; }}><IoArrowBackCircle className={styles.iconEdit} /></button>
            <button className={styles.buttonEditSave} onClick={() => { addJob(); }}><MdCheckCircle className={styles.iconEdit} /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftsHistory;
