import React, { useEffect, useState } from 'react';
import { Container, Nav, NavDropdown, Navbar, Offcanvas } from 'react-bootstrap';
import { AiOutlineQrcode } from "react-icons/ai";
import { BiCurrentLocation } from "react-icons/bi";
import { BsDatabaseFillAdd, BsPeopleFill } from 'react-icons/bs';
import { CgProfile } from "react-icons/cg";
import { FaPlay, FaListUl } from 'react-icons/fa';
import { GiLaserWarning } from "react-icons/gi";
import { MdFavorite, MdSell, MdWorkHistory } from "react-icons/md";
import { SiMicrosoftexcel } from "react-icons/si";
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { LuLineChart, LuPackage, LuPieChart } from "react-icons/lu";
import styles from './App.module.css';
import { Exit } from './Core.js';
import AdministratorNavBar from './components/Administrator/Navbar.js';
import AnalyzeNavBar from './components/Analytic/Navbar.js';
import EmployeeNavBar from './components/Employee/navbar.js';
import Feedback from './components/Main/Feedback.js';
import HomePage from './components/Main/Home.js';
import IconLoad from './components/Main/IconLoad.js';
import LoginPage from './components/Main/LoginPage.js';
import ManagerNavBar from './components/Manager/navbar.js';
import Profile from './components/Profile/Profile.js';
import { getAccount } from './apiMethod/Accounts/profile.js';

function App() {
  const expand = false;

  const [state, setState] = useState('');
  const [user, setUser] = useState();
  const [specialization, setSpecialization] = useState(window.location.pathname.toLowerCase());
  const [jwt, setJwt] = useState(localStorage.getItem('jwtToken'));

  const updateJwt = (value) => {
    localStorage.setItem('jwtToken', value);
    setJwt(value);
  }

  const getUser = async (jwt) => {

    const account = await getAccount(jwt);
    if (account)
      localStorage.setItem('user', JSON.stringify({ timeUpdate: new Date(), data: account }));
    else setState(null);

    return account;
  }

  useEffect(() => {

    async function load() {
      setUser(await getUser(jwt));
    }

    const userLocal = JSON.parse(localStorage.getItem('user'));

    if (userLocal != null && new Date(userLocal.timeUpdate).getDay() == new Date().getDay() && userLocal.data != null) {
      setUser(userLocal.data);
      setState(null);
    }
    else load();
  }, []);

  const Links = (specialization) => {

    if (specialization.includes('/'))
      specialization = '/' + specialization.split('/')[1];

    switch (specialization) {

      case '/administrator':
        localStorage.setItem('specialization', specialization);

        if(user.administrator.root == 'all')
          return [{
          isToggle: true,
          icon: CgProfile,
          path: "/Administrator",
          text: 'Профиль'
        }, {
          isToggle: true,
          icon: SiMicrosoftexcel,
          path: "/Administrator/excel",
          text: 'Excel'
        }, {
          isToggle: true,
          icon: MdWorkHistory,
          path: "/Administrator/editShift",
          text: 'История смен'
        }, {
          isToggle: true,
          icon: BiCurrentLocation,
          path: "/Administrator/currentShifts",
          text: 'Текущие смены'
        }, {
          isToggle: true,
          icon: AiOutlineQrcode,
          path: "/Administrator/timespan/codes",
          text: 'КОД доступа'
        }, {
          isToggle: true,
          icon: GiLaserWarning,
          path: "/Administrator/lasers",
          text: 'Лазеры'
        }, {
          isToggle: true,
          icon: BsPeopleFill,
          path: "/Administrator/Workers",
          text: 'Сотрудники'
        },{
          isToggle: true,
          icon: FaListUl,
          path: "/Administrator/ExtraWorks",
          text: 'Список работы'
        }];

        if(user.administrator.root == 'code')
          return [{
            isToggle: true,
            icon: AiOutlineQrcode,
            path: "/Administrator/timespan/codes",
            text: 'КОД доступа'
          }];

      case '/employee':
        localStorage.setItem('specialization', specialization);
        return [{
          isToggle: true,
          icon: CgProfile,
          path: '/Employee',
          text: 'Профиль'
        }, {
          isToggle: true,
          icon: MdWorkHistory,
          path: '/Employee/shifts/History',
          text: 'Смены'
        }, {
          isToggle: true,
          icon: MdFavorite,
          path: '/Employee/extraworks/favorites',
          text: 'Избранные работы'
        }, {
          isToggle: true,
          icon: BiCurrentLocation,
          path: '/Employee/shifts/current',
          text: 'Текущая смена'
        }, {
          isToggle: true,
          icon: FaPlay,
          path: '/Employee/startShift',
          text: 'Начать смену'
        }];

      case '/manager':
        localStorage.setItem('specialization', specialization);
        return [{
          isToggle: true,
          icon: CgProfile,
          path: '/Manager',
          text: 'Профиль'
        }, {
          isToggle: true,
          icon: MdSell,
          path: '/Manager/sellProduct',
          text: 'Оформить продукцию'
        }, {
          isToggle: true,
          icon: BsDatabaseFillAdd,
          path: '/Manager/lead',
          text: 'Оформить лида'
        }];

      case '/analyze':
        localStorage.setItem('specialization', specialization);
        return [{
          isToggle: true,
          icon: CgProfile,
          path: '/Analyze',
          text: 'Профиль'
        },{
          isToggle: true,
          icon: LuLineChart,
          path: '/Analyze/dynamicOrder',
          text: 'Динамика заказов'
        }, {
          isToggle: true,
          icon: LuPackage,
          path: '/Analyze/sell',
          text: 'Продажи'
        }, {
          isToggle: true,
          icon: LuPieChart,
          path: '/Analyze/profitability',
          text: 'Рентабельность'
        }];

      case '/':
        const spec = localStorage.getItem('specialization')

        if (spec == null || spec == '' || spec == '/') {

          let result = [];

          if (user.administrator)
            result.push({
              isToggle: true,
              icon: CgProfile,
              path: "/Administrator",
              text: 'Администратор'
            });

          if (user.worker)
            result.push({
              isToggle: true,
              icon: CgProfile,
              path: "/Employee",
              text: 'Производственный специалист'
            });

          if (user.manager)
            result.push({
              isToggle: true,
              icon: CgProfile,
              path: "/Manager",
              text: 'Менеджер'
            });

          if (user.analytic)
            result.push({
              isToggle: true,
              icon: CgProfile,
              path: "/Analyze",
              text: 'Аналитик'
            });

          return result;
        }
        else return Links(spec);
    }
  }

  const NavBarEG = ({ isСurtains, links, contentBodyCanvas, routesOnLinks, titleToggle }) => {

    const [isVisible, setIsVisible] = useState(false);

    const LinkOnToggle = ({ path, Icon, text }) => {
      return <Link className={styles.nav_link_toggle} onClick={() => setIsVisible(false)} to={path}>
        <Icon className={styles.nav_link_icon} />
        <div className={styles.nav_link_text}>{text}</div>
      </Link>;
    }

    const LinkOnTop = ({ path, Icon, text }) => {
      return <Link className={styles.nav_link_top} to={path}>
        <Icon className={styles.nav_link_icon} />
        <div className={styles.nav_link_text}>{text}</div>
      </Link>;
    }

    return <Router>
      <Navbar key={expand} expanded={isVisible} onToggle={() => setIsVisible(!isVisible)} expand={isСurtains} className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="/">EG GROUP</Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                {titleToggle}
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                {
                  links ?
                links.map((o, index) => o.isToggle ?
                  <LinkOnToggle key={index} Icon={o.icon} path={o.path} text={o.text} />
                  : <LinkOnTop key={index} Icon={o.icon} path={o.path} text={o.text} />)
                : null}
                {contentBodyCanvas}
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {routesOnLinks}
    </Router>;
  }

  if (user != null)
    return <NavBarEG isСurtains={false}
      titleToggle={user.name}
      contentBodyCanvas={
        <NavDropdown
          title="Сменить специальность"
          id={`offcanvasNavbarDropdown-expand-${expand}`}
        >
          {user.administrator === null ? null : <NavDropdown.Item href="/Administrator">Администратор</NavDropdown.Item>}
          {user.worker === null ? null : <NavDropdown.Item href="/Employee">Производственный специалист</NavDropdown.Item>}
          {user.manager === null ? null : <NavDropdown.Item href="/Manager">Менеджер</NavDropdown.Item>}
          {user.analytic === null ? null : <NavDropdown.Item href="/Analyze">Аналитик</NavDropdown.Item>}
          <NavDropdown.Divider />
          <NavDropdown.Item href="/profile">Редактировать профиль</NavDropdown.Item>
          <NavDropdown.Item href="/feedback">Предложения по улучшению</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item onClick={Exit}>
            Выход
          </NavDropdown.Item>
        </NavDropdown>
      } links={Links(specialization)}
      routesOnLinks={<div>
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path='/Login' element={<LoginPage />} />
          <Route path='/profile' element={<Profile user={user} jwt={jwt} updateUser={getUser} updateJwt={updateJwt} />} />
          <Route path='/feedback' element={<Feedback jwt={jwt} />} />
          {user.analytic === null ? null : <Route path='/Analyze/*' element={<AnalyzeNavBar jwt={jwt}/>} />}
          {user.worker === null ? null : <Route path='/Employee/*' element={<EmployeeNavBar jwt={jwt} />} />}
          {user.manager === null ? null : <Route path='/Manager/*' element={<ManagerNavBar jwt={jwt} />} />}
          {user.administrator === null ? null : <Route path='/Administrator/*' element={<AdministratorNavBar jwt={jwt} />} />}
        </Routes>
      </div>} />

  if (state == '')
    return <div className={styles.load}>
      <IconLoad />
    </div>


  return <NavBarEG
    links={[{ isToggle: false, icon: CgProfile, path: '/login', text: 'Вход' }]}
    routesOnLinks={
      <div>
        <Routes>
          <Route path="/*" element={<HomePage />} />
          <Route path='/Login' element={<LoginPage />} />
        </Routes>
      </div>
    }
    isСurtains={true}
  />;
}

export default App;
