import React, { useState } from "react";
import { HttpData } from "../../Core.js";
import styles from './loginPageStyle.module.css';

function LoginPage() {

    const [password, setPassword] = useState('');
    const [login, setLogin] = useState('');

    const ClickLogin = async () => {

        var response = await HttpData('/api/Authentication', 'POST', '', { login: login, password: password });

        if (response.statusSuccessful) {
            localStorage.setItem('jwtToken', response.data.token);
            window.location.href = '/';
        }
        else alert('Неверные данные');
    }

    return (
        <div className={styles.main_body}>
            <h2 className={styles.title}>Вход</h2>
                <div className={styles.content_container}>
                    <input className={styles.input} value={login} onChange={(e) => setLogin(e.target.value)} type="text" placeholder="Логин" />
                    <input className={styles.input} value={password} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Пароль" />
                    <button className={styles.button_login} onClick={ClickLogin}>Войти</button>
                </div>
         </div>
    );
}

export default LoginPage;