import { Route, Routes } from "react-router-dom";
import AnalyticLasers from "./AnalyticLasers.js";
import Codes from './Codes.js';
import ExcelExport from "./ExcelExport.js";
import Home from "./HomePage";
import ShiftCurrent from "./ShiftCurrent";
import ShiftEdit from "./ShiftEdit";
import Workers from './Workers.js';
import ExtraWorksView from "./ExtraWorksView.js";

function Navbar(props) {

  return <div>
    <Routes>
      <Route path="/*" element={<Home />} />
      <Route path="/excel" element={<ExcelExport jwt={props.jwt}/>}/>
      <Route path="/editShift" element={<ShiftEdit />} />
      <Route path="/currentShifts" element={<ShiftCurrent />} />
      <Route path="/lasers" element={<AnalyticLasers jwt={props.jwt}/>}/>
      <Route path="/workers" element={<Workers jwt={props.jwt}/>}/>
      <Route path="/extraWorks" element={<ExtraWorksView jwt={props.jwt}/>}/>
      <Route path='/timespan'>
        <Route path='codes' element={<Codes />} />
      </Route>
    </Routes>
  </div>

}

export default Navbar;