import React, { useEffect, useState } from "react";
import { GetHoursOnTimeSpan, HttpNoData, NormalStringTime, TimeSpanParse } from "../../../Core";
import styles from '../Style/CheckListShift.module.css';
import Spinner from 'react-bootstrap/Spinner';

function CheckListShift({ shiftId, jwt, works, className, childs }) {

    const [shift, setShift] = useState(null);
    const [stylePoints, setStylesPoints] = useState();

    useEffect(() => {

        async function DidMount() {

            const response = await HttpNoData('/api/shifts/check_list?shiftId=' + shiftId, 'GET', jwt);

            if (response.statusSuccessful && !response.isDataEmpty) {

                const temp = response.data;
                setShift(temp);

                const averagePoints = temp.sumPoints / GetHoursOnTimeSpan(temp.length);

                if (averagePoints >= 0.875)
                    setStylesPoints(styles.goodPoints);
                else if (averagePoints >= 0.75)
                    setStylesPoints(styles.warningPoints);
                else setStylesPoints(styles.minimumPoints);
            } else console.log(response.error);
        }

        DidMount();
    }, []);

    if (shift == null)
        return <Spinner animation='grow'/>

    return <div className={styles.contentBody + ' ' + className}>
        <h2>Чек-лист</h2>

        <div className={styles.content}>
            <div className={styles.contentElement}>
                Время: {NormalStringTime(new Date(shift.start.split('+')[0]))} - {NormalStringTime(new Date(shift.end.split('+')[0]))}
            </div>

            <div className={styles.contentElement}>
                Длительность: {TimeSpanParse(shift.length)}
            </div>
            <div className={styles.contentElement}>
                Кол-во ночных ч.: {TimeSpanParse(shift.night)}
            </div>
            <div className={styles.contentElement}>
                Кол-во дневных ч.: {TimeSpanParse(shift.day)}
            </div>
            <div className={styles.contentElement}>
                Кол-во выходных ч.: {TimeSpanParse(shift.dayOff)}
            </div>

            <div className={styles.contentElement + ' ' + styles.works}>
                {
                    shift.metaExtraWorks.length <= 0 ?
                        <label>Уверены что хотите закрыть смену без работ?</label> :
                        shift.metaExtraWorks.map((work, index) => <div key={index} className={styles.work}>
                                {
                                    works.filter(o => o.value.extraWorkId == work.extraWorkId)[0].value.name
                                }: {work.value} ({work.points})
                            </div>)
                }
                {
                    shift.isMachines ? 
                    <div className={styles.work}>
                        Лазерные станки: {shift.machinePercent}%
                    </div>: null
                }
            </div>

            <div className={styles.contentElement + ' ' + stylePoints}>
                Сумма баллов: {shift.sumPoints}
            </div>
            <div className={styles.contentElement + ' ' + stylePoints}>
                Повышенные: {shift.upPoints}
            </div>
            <div className={styles.contentElement + ' ' + stylePoints}>
                Сложные: {shift.highPoints}
            </div>
            <div className={styles.contentElement + ' ' + stylePoints}>
                Средние: {shift.middlePoints}
            </div>
            <div className={styles.contentElement + ' ' + stylePoints}>
                Простые: {shift.simplePoints}
            </div>

            {
                shift.isMachines ? 
                <div className={styles.contentElement + ' ' + stylePoints}>
                    Станки: {shift.machinePoints}
                </div> : null
            }

            {childs ? childs() : null}
        </div>
    </div>
}

export default CheckListShift;